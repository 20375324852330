<template>
  <div class="container mt-2">
    <b-row>
      <b-col md="3" />
      <b-col md="6">
        <div class="backg">
          <h4 style="margin-top: 5px">
            <router-link :to="{ name: 'home' }">
              <i class="fas fa-chevron-left fa-lg" />
              <span style="color: #000"> ทำรายการฝากเงิน</span>
            </router-link>
          </h4>
        </div>
        <div class="depo">
          <b-row>
            <b-col
              lg="6"
              cols="6"
            >
              <b-button
                variant="gradient-primary"
                block
                @click=";(click1 = true), (click2 = false)"
              >
                <i
                  class="fas fa-university"
                  style="color: #000"
                />
                <span style="color: #000"> ธนาคาร</span>
              </b-button>
            </b-col>

            <b-col
              lg="6"
              cols="6"
            >
              <b-button
                variant="gradient-warning"
                block
                @click=";(click2 = true), (click1 = false)"
              >
                <i
                  class="fa-solid fa-wallet"
                  style="color: #fff"
                />
                <span style="color: #fff"> ทรูมันนี่วอลเล็ท</span>
              </b-button>
            </b-col>
          </b-row>

          <div class="notice__list alert mt-1">
            <p style="margin-bottom: 5px; text-decoration: underline">
              <strong>ขั้นตอนการถอนเครดิต</strong>
            </p>
            <p style="margin-top: -5px">
              1). โอนเงินเข้าบัญชีแจ้งฝากตามข้อมูลด้านล่าง
            </p>
            <p style="margin-top: -5px">
              2). ระบบจะเติมเงินเข้าบัญชีของท่านโดยอัตโนมัติ
            </p>
          </div>

          <div
            class="alert"
            style="
              font-size: 0.85rem;
              margin-bottom: 10px;
              background-color: #898686;
            "
          >
            <p style="margin-bottom: 5px">
              <span
                style="font-weight: bold; text-decoration: underline"
              >เตือน!</span>
              <span
                style="margin-left: 5px"
              >ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้น</span>
            </p>
          </div>

          <div v-if="click1 == true">
            <div
              class="card"
              :style="`background-color: ${AgentData.bg};`"
            >
              <div class="logo">
                <img
                  :src="`/bankIcon/${AgentData.path_photo}`"
                  alt="logo-bank"
                  height="75"
                  style="float: right"
                  class="mt-2"
                >
              </div>
              <div class="chip">
                <img
                  src="/images/chip.png"
                  alt="chip"
                >
                <span class="bankk">{{ AgentData.bankname }}</span>
              </div>
              <div class="number">
                {{ AgentData.dip_bank_accno }}
              </div>
              <div class="name">
                {{ AgentData.dip_bank_accname }}
              </div>
              <div class="from">
                THAILAND
              </div>
              <div class="to">
                06/23
              </div>
              <div class="ring" />
            </div>

            <b-button
              v-clipboard:copy="message"
              block
              class="mt-1"
              @click="showToast1()"
            >
              <i class="far fa-copy" />
              คัดลอกเลขบัญชี
            </b-button>
          </div>

          <!-- True wallet -->
          <div
            v-if="click2 == true"
            class="balance-true p-1 mt-4"
          >
            <b-row>
              <b-col cols="8">
                <div class="pt-1">
                  <h6 class="text-primary">
                    ทรูมันนี่วอลเล็ท
                  </h6>
                  <p style="font-size: 2rem; color: #ff7b00">
                    {{ AgentData.truemoney_acc }}
                  </p>

                  <span>ชื่อบัญชี </span>
                  <h6 style="color: #ff7b00">
                    {{ AgentData.truemoney_name }}
                  </h6>
                </div>
              </b-col>
              <b-col cols="4">
                <img
                  src="/bankIcon/wallet.png"
                  alt=""
                  height="65"
                  class="mt-2 pr-2"
                >
              </b-col>
            </b-row>
            <b-button
              v-clipboard:copy="num_true"
              variant="primary"
              @click="showToast1()"
            >
              <i class="far fa-copy" />
              คัดลอกเลขบัญชี
            </b-button>
          </div>

          <div class="text-center">
            <small class="mt-2 text-center text-danger">
              ** หากโอนเงินมาระหว่าง 23.00 - 23.59 จะได้รับเครดิตหลังจาก 00.00
              เป็นต้นไป
            </small>
          </div>
        </div>
      </b-col>
      <b-col md="3" />
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'

export default {
  components: { BRow, BCol, BButton },
  data() {
    return {
      click1: true,
      click2: false,
      num_true: '',
      message: '',
      AgentData: {
        dip_bank_code: '',
        dip_bank_accno: '',
        dip_bank_accname: '',
        truemoney_acc: '',
        truemoney_name: '',
        bankname: '',
        bg: '',
        path_photo: '',
      },
    }
  },
  mounted() {
    this.ShowAgent()
  },
  methods: {
    async ShowAgent() {
      try {
        const { data: response } = await this.$http.get(
          'https://api.ma5lotto.com/api/agent/show',
        )
        if (response && response.success === true) {
          this.AgentData = response.AgentData
          this.message = this.AgentData.dip_bank_accno
          this.num_true = this.AgentData.truemoney_acc
        }
      } catch (err) {
        console.log(err)
      }
    },
    showToast1() {
      this.$toast.success('คัดลอกเลขบัญชีสำเร็จ', {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'far fa-copy',
        rtl: false,
      })
    },
  },
}
</script>

<style scoped>
.alert {
  border-radius: 4px;
  border: none;
  /* color: #fff !important; */
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.backg {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  /* margin-left: 5px;
  margin-right: 5px; */
  border-radius: 15px;
  font-family: 'Mitr', sans-serif;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.depo {
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.img-bank {
  margin: auto;
  background: rgb(71, 1, 104);
  padding: 5px;
  height: 160px;
  width: 160px;
  border-radius: 50%;
}
.balance-true {
  margin: auto;
  border: 1px solid #e42126;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  max-width: 640px;
  height: 100%;
  color: #000;
  position: relative;
}
.notice__list {
  font-size: 18px;
}
.notice__list {
  font-size: 0.85rem;
  margin: auto;
  max-width: 640px;
  background: linear-gradient(47deg, #accb32, #bdd65a);
  /* border: 1px solid rgb(255, 0, 0); */
  /* padding: 15px 24px; */
  /* border-radius: 10px; */
  color: rgb(0, 0, 0);
  margin-bottom: 0.75rem;
}
.notice__list p.alert {
  display: flex;
}
.btn-copy-2 {
  color: #000000;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
}
.btn {
  /* border-radius: 38px; */
  color: rgb(0, 0, 0);
  background-color: #ffc800;
}
.balance-cl {
  margin: auto;
  /* background-color: #4e2e7f; */
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  max-width: 640px;
  height: 100%;
  color: #000;
  position: relative;
}
.balance-bay {
  background-color: #ffc323;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .ftn {
    font-size: 1.2rem;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.font-res {
  font-size: 38px;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .font-res {
    font-size: 2rem;
  }
}
/* Background circles start */
.circle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(#006db3, #29b6f6);
}
.circles {
  position: absolute;
  height: 270px;
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-1 {
  height: 180px;
  width: 180px;
  top: -50px;
  left: -60px;
}
.circle-2 {
  height: 200px;
  width: 200px;
  bottom: -90px;
  right: -90px;
  opacity: 0.8;
}
/* Background circles end */

/* .card-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.card {
  margin: auto;
  position: relative;
  height: 210px;
  max-width: 380px;
  border-radius: 15px;
  /* background: rgb(163, 0, 0); */
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.logo img,
.chip img,
.number,
.bankk,
.name,
.from,
.to,
.ring {
  position: absolute; /* All items inside card should have absolute position */
}

.logo img {
  top: 15px;
  right: 10px;
  width: 80px;
  height: auto;
  opacity: 0.8;
}

.chip img {
  top: 60px;
  left: 20px;
  width: 50px;
  height: auto;
  opacity: 0.8;
}
.bankk {
  top: 20px;
  left: 20px;
  font-size: 1.3rem;
  color: #fff;
}

.number,
.name,
.from,
.to {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.number {
  left: 20px;
  font-size: 1.5rem;
  bottom: 65px;
  font-family: 'Nunito', sans-serif;
}

.name {
  font-size: 1rem;
  left: 20px;
  bottom: 35px;
}

.from {
  font-size: 1rem;
  bottom: 35px;
  right: 90px;
}

.to {
  font-size: 1rem;
  bottom: 35px;
  right: 20px;
}

/* The two rings on the card background */
.ring {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: transparent;
  border: 50px solid rgba(255, 255, 255, 0.1);
  bottom: -250px;
  right: -250px;
  box-sizing: border-box;
}

.ring::after {
  content: '';
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.1);
  bottom: -80px;
  right: -110px;
  box-sizing: border-box;
}
</style>
